import Swiper from 'swiper';
import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';

const { get, getAll, addClass, removeClass } = $dom;
const { active } = variables.classNames;

const productListSelector = '.js-products-list';
const productListItemSelector = '.js-products-list-item';

export function productsFunctional() {

  let productSlider = null;

  const clearClasses = () => {
    getAll(productListItemSelector).forEach(element => {
      removeClass(element, active)
    });
  }

  const mouseoverHandler = event => {
    let target = event.target;

    if (target.closest(productListItemSelector)) {
      target = target.closest(productListItemSelector);

      clearClasses();

      addClass(target, active);
    }
  }

  const productSliderInitializer = slider => {

    console.log(window.innerWidth);

    if (window.innerWidth >= 992) {
      if(slider instanceof Swiper) {
        slider.destroy();

        return;
      }
    } else {
      return slider = new Swiper(productListSelector, {
        spaceBetween: 20,
        
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.4,
          },
          // when window width is >= 480px
          576: {
            slidesPerView: 2,
          },
          // when window width is >= 640px
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 6,
          },
          // 1440: {
          //   slidesPerView: 2.5,
          //   spaceBetween: 0,
          // }
        }
      })
    }
  }

  return new Component({
    name: 'productsFunctional',
    requiredTargets: productListSelector,
    onCreate() { },
    onInit() {

      productSliderInitializer(productSlider);

      $events.resize('on', productSliderInitializer(productSlider));

      $events.delegate
        .on('mouseover', productListItemSelector, mouseoverHandler)
    },
    onDestroy() {
      $events.delegate
        .off('mouseover', productListItemSelector, mouseoverHandler)
     }
  })
}