import { Modal } from 'bootstrap';
import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';

const {get} = $dom;

const callUsSelector = '.js-callus-modal-open';

export function callusModal() {

  const callusModal = new Modal('#js-callus-modal');

  function getScrollBarWidth() {
    let el = document.createElement("div");
    el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
    document.body.appendChild(el);
    let width = el.offsetWidth - el.clientWidth;
    el.remove();
    return width;
  }

  return new Component({
    name: 'callusModal',
    requiredTargets: callUsSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('click', callUsSelector, () => {
          get('.js-header').style.paddingRight = `${getScrollBarWidth()}px`;
          callusModal.show();
        })
        .on('hidden.bs.modal', window, () => {
          get('.js-header').style.paddingRight = 0;
        })
     },
    onDestroy() { }
  })
}