import gsap from 'gsap';
import Component from '../../classes/Component';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

const infoContentSelector = '.js-info-content';
const infoContentPanelSelector = '.js-info-content-panel';

export function informationAnimation() {

  ScrollTrigger.defaults({
    toggleActions: "play none none reverse",
    // markers: true
  });

  gsap.utils.toArray(infoContentPanelSelector).forEach((panel, i) => {
    ScrollTrigger.create({
      trigger: panel,
      start: "top top",
      // end: "center center",
      // end: i == 2 ? "+=70%" : "+=100%",
      end: i == 2 ? "+=70%" : "+=100%",
      pin: true,
      pinSpacing: false,
      scrub: 1,
      // snap: {
      //   snapTo: 1,
      //   duration: { min: 0.5, max: 0.5 }
      // }
    });
  });

  return new Component({
    name: 'informationAnimation',
    requiredTargets: infoContentSelector,
    onCreate() { },
    onInit() { },
    onDestroy() { }
  })
}