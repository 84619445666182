import Swiper from 'swiper';
import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';

const {get, getAll, addClass, removeClass, attr} = $dom;
const {current} = variables.classNames;

const mapSelector = '.js-map';

const tabsSelector = '.js-contacts-tabs';
const tabsSliderSelector = '.js-tabs-slider';
const tabsItemSelector = '.js-contacts-tabs-item';

const dataCoorsX = 'data-coords-x';
const dataCoorsY = 'data-coords-y';
const dataStorageCoordsX = 'data-storage-coords-x';
const dataStorageCoordsY = 'data-storage-coords-y';

export function mapFunctional() {

  let allowClick = null;

  const storagePointsSetter = (map) => {
    getAll(tabsItemSelector).forEach(element => {
      
      const coordsX = attr(element, dataStorageCoordsX);
      const coordsY = attr(element, dataStorageCoordsY);

      let placemark = new ymaps.Placemark([coordsX, coordsY], {
        hintContent: '',
        balloonContent: ''
      }, {
        iconLayout: 'default#image',
        // iconImageHref: './img/svg/placemark.svg',
        iconImageSize: [36, 36],
        iconImageOffset: [-18, -18]
      });

      map.geoObjects.add(placemark);

      placemark = null;
    });
  }

  // Функция ymaps.ready() будет вызвана, когда
  // загрузятся все компоненты API, а также когда будет готово DOM-дерево.
  ymaps.ready(init);

  let map = null;

  function init() {
    // Создание карты.
    map = new ymaps.Map("map", {
      // Координаты центра карты.
      // Порядок по умолчанию: «широта, долгота».
      // Чтобы не определять координаты центра карты вручную,
      // воспользуйтесь инструментом Определение координат.
      center: [55.76, 37.64],
      // Уровень масштабирования. Допустимые значения:
      // от 0 (весь мир) до 19.
      zoom: 7
    });
    // var placemark = new ymaps.Placemark([55.76, 37.64], {
    //   hintContent: '',
    //   balloonContent: ''
    // }, {
    //   iconLayout: 'default#image',
    //   // iconImageHref: './img/svg/placemark.svg',
    //   iconImageSize: [36, 36],
    //   iconImageOffset: [-18, -18]
    // });

    // console.log(placemark);

    storagePointsSetter(map);

    // map.geoObjects.add(placemark);
    map.behaviors.disable('scrollZoom');
  }

  const clearTabs = () => {
    getAll(tabsItemSelector).forEach(element => {
      removeClass(element, current)
    });
  }

  const tabsHandler = event => {
    const target = event.target;

    if (!allowClick) return;

    if (target.closest(tabsItemSelector)) {
      const tabItem = target.closest(tabsItemSelector);

      const coordX = attr(tabItem, dataCoorsX);
      const coordY = attr(tabItem, dataCoorsY);

      clearTabs();

      map.setCenter([coordX, coordY], 7);

      addClass(tabItem, current);
    }
  }

  return new Component({
    name: 'mapFunctional',
    requiredTargets: mapSelector,
    onCreate() { },
    onInit() {

      this.tabsSlider = new Swiper(tabsSliderSelector, {
        spaceBetween: 10,
        slidesPerView: 3,

        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 8,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 8,
          },
          // 1200: {
          //   slidesPerView: 4,
          //   spaceBetween: 20,
          // },
        },

        on: {
          // init: element => {

          //   getAll(gallerySliderCounterSelector).forEach(elem => {
          //     elem.innerHTML = element.slides.length;
          //   })
          // },

          touchStart: function () {
            allowClick = true;
          },

          touchMove: function () {
            allowClick = false;
          },

          touchEnd: function () {
            setTimeout(function () {
              allowClick = true;
            }, 100);
          },
        }
      })

      $events.delegate
        .on('click', tabsSelector, tabsHandler)
     },
    onDestroy() {
      $events.delegate
        .off('click', tabsSelector, tabsHandler)
     }
  })
}