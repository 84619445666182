import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';
import variables from '../variables';

const { get, getAll, toggleClass } = $dom;
const { active } = variables.classNames;

const dropdownSelector = '.js-dropdown';
const dropdownButtonSelector = '.js-dropdown-button';
const dropdownContentSelector = '.js-dropdown-content';

export function dropdownFunctional() {

  const dropdownHandler = event => {
    const target = event.target;

    if (target.closest(dropdownButtonSelector)) {
      const button = target.closest(dropdownButtonSelector);
      const dropdown = button.parentNode;
      const content = get(dropdownContentSelector, dropdown);

      toggleClass(button, active);
      toggleClass(dropdown, active);
      toggleClass(content, active);
    }
  }

  return new Component({
    name: 'dropdownFunctional',
    requiredTargets: dropdownSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('click', dropdownSelector, dropdownHandler)
    },
    onDestroy() {
      $events.delegate
        .off('click', dropdownSelector, dropdownHandler)
    }
  })
}