import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';

const { get, getAll, hasClass } = $dom;

const rangeSelector = '.js-range';
const rangeFirstSelector = '.js-range-first';
const rangeSecondSelector = '.js-range-second';
const rangeTrackSelector = '.js-range-track';

export function rangeFunctional() {

  let minGap = 0;

  if (!get(rangeSelector) instanceof HTMLElement) return;

  getAll(rangeSelector).forEach(element => {
    const sliderFirst = get(rangeFirstSelector, element);
    const sliderSecond = get(rangeSecondSelector, element);
    const sliderTrack = get(rangeTrackSelector, element);
    const sliderMaxValue = sliderFirst.max;

    fillColor(sliderFirst, sliderSecond, sliderTrack, sliderMaxValue);
  });

  function slideFirst(event) {

    const sliderFirst = event.target.closest(rangeFirstSelector);
    const sliderSecond = get(rangeSecondSelector, sliderFirst.parentNode);
    const sliderTrack = get(rangeTrackSelector, sliderFirst.parentNode);
    const sliderMaxValue = sliderFirst.max;

    if (hasClass(sliderFirst, 'disabled')) return;

    if (parseInt(sliderSecond.value) - parseInt(sliderFirst.value) <= minGap) {
      sliderFirst.value = parseInt(sliderSecond.value) - minGap;
    }

    fillColor(sliderFirst, sliderSecond, sliderTrack, sliderMaxValue);
  }

  function slideSecond(event) {

    const sliderSecond = event.target.closest(rangeSecondSelector);
    const sliderFirst = get(rangeFirstSelector, sliderSecond.parentNode);
    const sliderTrack = get(rangeTrackSelector, sliderSecond.parentNode);
    const sliderMaxValue = sliderFirst.max;

    if (parseInt(sliderSecond.value) - parseInt(sliderFirst.value) <= minGap) {
      sliderSecond.value = parseInt(sliderFirst.value) + minGap;
    }

    fillColor(sliderFirst, sliderSecond, sliderTrack, sliderMaxValue);
  }

  function fillColor(sliderFirst, sliderSecond, sliderTrack, sliderMaxValue) {
    let percent1 = (sliderFirst.value / sliderMaxValue) * 100;
    let percent2 = (sliderSecond.value / sliderMaxValue) * 100;
    sliderTrack.style.background = `linear-gradient(to right, #E5E5E5 ${percent1}% , #0E6CB8 ${percent1}% , #0E6CB8 ${percent2}%, #E5E5E5 ${percent2}%)`;
  }

  return new Component({
    name: 'rangeFunctional',
    requiredTargets: rangeSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('input', rangeFirstSelector, slideFirst)
        .on('input', rangeSecondSelector, slideSecond)
    },
    onDestroy() {
      $events.delegate
        .off('input', rangeFirstSelector, slideFirst)
        .off('input', rangeSecondSelector, slideSecond)
     }
  })
}