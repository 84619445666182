import Component from '../classes/Component';
import { $events } from '../helpers/events';

const inputSelector = '.js-numbers-only';

export function numbnersOnly() {
  return new Component({
    name: 'numbnersOnly',
    requiredTargets: inputSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('input', inputSelector, event => {
          const input = event.target.closest(inputSelector);

          console.log(input.value);

          input.value = input.value.replace(/[^\d\.,]/g, "");
          input.value = input.value.replace(/,/g, ".");
          if (input.value.match(/\./g).length > 1) {
            input.value = input.value.substr(0, input.value.lastIndexOf("."));
          }

        })
    },
    onDestroy() {
      $events.delegate
        .off('input', inputSelector, event => {
          const input = event.target.closest(inputSelector);

          console.log(input.value);

          input.value = input.value.replace(/[^\d\.,]/g, "");
          input.value = input.value.replace(/,/g, ".");
          if (input.value.match(/\./g).length > 1) {
            input.value = input.value.substr(0, input.value.lastIndexOf("."));
          }

        })
    }
  })
}