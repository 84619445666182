import Page from '../../classes/Page';
import { informationAnimation } from './informationAnimation';
import { productsFunctional } from './productsFunctional';

const homePage = new Page({
  name: 'home',
  rootElementId: 'js-page-home',
  
  onInit() {

    this
      .addComponent(productsFunctional)
      .addComponent(informationAnimation)
  },
});

export default homePage;
