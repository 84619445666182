import Page from '../../classes/Page';
import { mapFunctional } from './mapFunctional';
// import { informationAnimation } from './informationAnimation';
// import { productsFunctional } from './productsFunctional';

const contactsPage = new Page({
  name: 'contacts',
  rootElementId: 'js-page-contacts',
  
  onInit() {

    this
      .addComponent(mapFunctional)
      // .addComponent(informationAnimation)
  },
});

export default contactsPage;
